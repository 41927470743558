import React from "react"
import { Link, graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import Social from "../components/social.js"
import { FaArrowAltCircleLeft, FaRegFilePdf } from "react-icons/fa"
import Layout from "../components/LayoutAlt.js"

export default function Word({ data }) {
  const word = data.strapiBiblestudies
  const { content } = data.strapiBiblestudies

  return (
    <Layout>
      <div className="sm:w-3/4 sm:ml-1/8   sm:mt-10 xl:mt-20 xs:p-4 sm:p-0">
        <Link to={`/biblestudy/${word.category}`}>
          <section
            className="font-opensans  text-white  bg-orange-dark mt-5 xl:mt-10 py-1 pl-4 
              rounded-r-xl flex items-center"
          >
            <FaArrowAltCircleLeft size="20" className="mr-2 cursor-pointer" />
            <p className=" text-xs sm:text-base">
              /biblestudy/{word.category}/{word.slug}
            </p>
          </section>
        </Link>

        <div className="font-playfairdisplay font-bold text-5xl lg:text-7xl text-blue-dark text-center mt-10 md:mt-16 lg:mt-24">
          {word.title}
        </div>
        <p className="font-opensans text-center text-gray-500 text-sm focus-within:lg:text-lg mt-4">
          <ReactMarkdown children={word.question} />
        </p>
        <div className="flex sm:ml-1/8 lg:ml-1/4 3xl:ml-1/3 items-center font-opensans text-xs sm:text-sm  my-5 sm:my-10 text-gray-500">
          <img
            src={word.author.avatar.formats.thumbnail.url}
            alt="Author"
            className="w-10 h-10 rounded-full border-2"
          />
          <p className="ml-2">{word.author.name}</p>
          <p className="ml-2">{word.date}</p>
          <p className="sm:ml-2 xs:hidden sm:flex">{word.readtime}</p>
        </div>
        <div className="">
          <img
            srcSet={word.imgix_path}
            src={word.src_image}
            alt="Word"
            className="2xl:w-4/5 2xl:ml-1/10  h-84 w-full xl:h-108 object-cover"
          />
        </div>
        <div className="2xl:w-4/5 2xl:ml-1/10 my-10">
          <article className="articles">
            <ReactMarkdown children={content} />
          </article>
        </div>
        <div className={word.pdf ? "block" : "hidden"}>
          <div className="border-b-8 border-orange-dark 2xl:ml-1/10 mt-20 w-24"></div>
          <div className="xs:space-y-4 sm:space-y-0 sm:flex items-center 2xl:w-4/5 2xl:ml-1/10 mt-10">
            <div>
              <h3 className="font-opensans font-bold text-gray-700 ">
                {word.downloads} -{" "}
              </h3>
            </div>
            <div>
              <a href={word.pdf}>
                <FaRegFilePdf size="40" className="text-orange-dark" />
              </a>
            </div>
            <div></div>
          </div>
        </div>

        <section className="mt-10 mb-20">
          <Link to={`/biblestudy/${word.category}`}>
            <button className="font-opensans font-bold text-white bg-orange-dark ring ring-orange-light ring-offset-2 rounded-sm py-1 px-8">
              Back
            </button>
          </Link>
        </section>
      </div>
      <Social />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    strapiBiblestudies(slug: { eq: $slug }) {
      title
      question
      downloads
      pdf
      slug
      category
      date(formatString: "LL")
      readtime
      content
      author {
        avatar {
          formats {
            thumbnail {
              url
            }
          }
        }
        name
      }
      src_image
      imgix_path
      id
    }
  }
`
